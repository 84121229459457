import { useMutation, useQuery, useQueryClient } from 'react-query'
import { pullShipments, getShipments, removeShipment } from '../../api/shipments'

export function usePullShipmentsMutation() {
  return useMutation((jobnumberId: number) => pullShipments(jobnumberId))
}

export function useShipmentsQuery(jobnumberId: number) {
  const enabled = !!jobnumberId
  return useQuery(['shipments', jobnumberId], () => getShipments(jobnumberId), { enabled: enabled })
}

export function useRemoveShipmentMutation() {
  const queryClient = useQueryClient()
  const reload = () => {
    return queryClient.invalidateQueries('shipments')
  }

  return useMutation(removeShipment, { onSuccess: reload })
}
