import * as React from 'react'

type QuickTimePickerProps = {
  setTimeFields: (timeFields: any) => void
  timeFields: any
}

export default function QuickTimePicker(props: QuickTimePickerProps) {
  const { setTimeFields, timeFields } = props

  const clickHandlerFunction = (time: number) => {
    return e => {
      e.preventDefault()
      setTimeFields({ ...timeFields, time })
    }
  }
  return (
    <span className="d-inline-block">
      <button className="btn btn-outline-secondary mr-2" onClick={clickHandlerFunction(5)}>
        5 min.
      </button>
      <button className="btn btn-outline-secondary mr-2" onClick={clickHandlerFunction(10)}>
        10 min.
      </button>
      <button className="btn btn-outline-secondary" onClick={clickHandlerFunction(15)}>
        15 min.
      </button>
    </span>
  )
}
