import * as React from 'react'
import Select, { createFilter } from 'react-select'
import { week_list_path } from '../../routes'
import { UserShape } from '../../api/api_data_types'

type DepartmentsPickerProps = {
  startDate: string
  endDate: string
  availableUsers: UserShape[]
  selectedUserIds: number[]
  selectedDepartmentIds: number[]
}
export default function UsersPicker(props: DepartmentsPickerProps) {
  const { startDate, endDate, availableUsers, selectedUserIds, selectedDepartmentIds } = props
  const options = availableUsers.map(t => {
    return { value: t.id, label: t.name, user: t }
  })
  const selectedOptions = selectedUserIds.map(n => options.find(o => o.value === n))
  const filterConfig = {
    stringify: option => `${option.label} ${option.data.user.email} ${option.data.user.username}`,
  }
  return (
    <Select
      id="week-list-users-filter"
      placeholder="Vælg medarbejdere"
      isMulti
      value={selectedOptions}
      onChange={opts => {
        const ids = opts === null ? [] : opts.map(o => o.value)
        let url = week_list_path({ start_date: startDate, end_date: endDate })

        selectedDepartmentIds.forEach(id => {
          url += `&department_ids[]=${id}`
        })

        ids.forEach(id => {
          url += `&user_ids[]=${id}`
        })

        window.location.href = url
      }}
      options={options}
      filterOption={createFilter(filterConfig)}
    />
  )
}
