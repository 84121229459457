import * as React from 'react'
import { useStorageState } from 'react-storage-hooks'
import WeekListTable from './WeekListTable'
import ShowWeekendsSwitcher from './ShowWeekendsSwitcher'
import FilterAndExportRow from './FilterAndExportRow'
import { UserShape } from '../../api/api_data_types'

interface WeekListAppProps {
  startDate: string
  endDate: string
  data: any[]
  availableDepartments: any[]
  selectedDepartmentIds: number[]
  availableUsers: UserShape[]
  selectedUserIds: number[]
}

export default function WeekListApp(props: WeekListAppProps) {
  const { startDate, endDate, data, availableDepartments, selectedDepartmentIds, selectedUserIds, availableUsers } = props
  const [showWeekends, setShowWeekends] = useStorageState(sessionStorage, 'showWeekends', true)

  return (
    <>
      <FilterAndExportRow
        startDate={startDate}
        endDate={endDate}
        availableDepartments={availableDepartments}
        selectedDepartmentIds={selectedDepartmentIds}
        availableUsers={availableUsers}
        selectedUserIds={selectedUserIds}
      />
      <ShowWeekendsSwitcher showWeekends={showWeekends} setShowWeekends={setShowWeekends} />
      <WeekListTable startDate={startDate} endDate={endDate} showWeekends={showWeekends} data={data} />
    </>
  )
}
