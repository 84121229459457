import * as React from 'react'
import Select from 'react-select'
import { week_list_path } from '../../routes'

type DepartmentsPickerProps = {
  startDate: string
  endDate: string
  availableDepartments: any[]
  selectedDepartmentIds: number[]
  selectedUserIds: number[]
}
export default function DepartmentsPicker(props: DepartmentsPickerProps) {
  const { startDate, endDate, availableDepartments, selectedDepartmentIds, selectedUserIds } = props
  const options = availableDepartments.map(t => {
    return { value: t[1], label: t[0] }
  })
  const selectedOptions = selectedDepartmentIds.map(n => options.find(o => o.value === n))
  return (
    <Select
      id="week-list-departments-filter"
      placeholder="Vælg afdeling"
      isMulti
      value={selectedOptions}
      onChange={opts => {
        const ids = opts === null ? [] : opts.map(o => o.value)
        let url = week_list_path({ start_date: startDate, end_date: endDate })
        ids.forEach(id => {
          url += `&department_ids[]=${id}`
        })
        selectedUserIds.forEach(id => {
          url += `&user_ids[]=${id}`
        })
        window.location.href = url
      }}
      options={options}
    />
  )
}
