import * as React from 'react'

type TimeFieldsSignControlProps = {
  signMultiplier: number
  setTimeFields: (timeFields: any) => void
  timeFields: any
}

export default function TimeFieldsSignControl(props: TimeFieldsSignControlProps) {
  const { signMultiplier, setTimeFields, timeFields } = props
  return (
    <div className="form-group mr-1">
      <div>
        <div className="btn-group btn-group-toggle">
          <label className={`btn ${signMultiplier >= 0 ? 'active resources-adjustment-up' : 'btn-outline-secondary '}`}>
            <input
              type="radio"
              checked={signMultiplier >= 0}
              onChange={e => setTimeFields({ ...timeFields, time: Math.abs(timeFields.time) })}
            />
            <span>+</span>
          </label>
          <label className={`btn ${signMultiplier < 0 ? 'active resources-adjustment-down' : 'btn-outline-secondary '}`}>
            <input
              type="radio"
              checked={signMultiplier < 0}
              onChange={e => {
                const newTime = timeFields.time === undefined || timeFields.time === 0 ? -60 : -1 * Math.abs(timeFields.time)
                setTimeFields({ ...timeFields, time: newTime })
              }}
            />
            <span>-</span>
          </label>
        </div>
      </div>
    </div>
  )
}
