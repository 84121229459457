import * as React from 'react'
import { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import MinutesPicker from './MinutesPicker'
import HoursPicker from './HoursPicker'
import { separateTime } from '../shared/utils'
import RemovedField from './RemovedField'
import QuickTimePicker from './QuickTimePicker'
import TimeFieldsSignControl from './TimeFieldsSignControl'

function TimeFields({ className, idKey, timeFields, setTimeFields, showSignControl, availableWorktypes, fieldNameFn, handleRemove }) {
  const timeId = timeFields.id || null
  const worktypeId = timeFields.worktype_id || ''
  const comment = timeFields.comment || ''
  let signMultiplier = timeFields.time && timeFields.time < 0 ? -1 : 1
  const [hours, minutes] = separateTime(Math.abs(timeFields.time))
  useEffect(() => {
    signMultiplier = timeFields.time && timeFields.time < 0 ? -1 : 1
  }, [timeFields.time])
  const fieldId = field => `${idKey}-field-${field}`

  if (timeFields._destroy === true) {
    return <RemovedField field={timeFields} fieldNameFn={fieldNameFn} />
  }

  return (
    <fieldset className={`position-relative form-fieldset ${className}`}>
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={fieldId('worktype_id')}>Arbejdstype</label>
          <select
            id={fieldId('worktype_id')}
            name={fieldNameFn('worktype_id')}
            className="form-control resources-time-worktype"
            value={worktypeId}
            onChange={e => setTimeFields({ ...timeFields, worktype_id: e.target.value })}
            required
          >
            <option />
            {availableWorktypes.map(worktype => {
              const [title, id] = worktype
              return (
                <option key={id} value={id}>
                  {title}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={fieldId('comment')}>Beskrivelse</label>
          <input
            id={fieldId('comment')}
            name={fieldNameFn('comment')}
            className="form-control resources-time-comment"
            value={comment}
            onChange={e => setTimeFields({ ...timeFields, comment: e.target.value })}
          />
        </div>
      </div>
      <div className="row">
        <div className={`${showSignControl ? 'col-md-5' : 'col-md-7'} form-group`}>
          <label>
            Hurtig tid
          </label>
          <div>
            <QuickTimePicker setTimeFields={setTimeFields} timeFields={timeFields} />
          </div>
        </div>
        <div className={showSignControl ? 'col-md-7' : 'col-md-5'}>
          <div className="resources-time-selects">
            <div className="form-group w-100">
              <label className="d-flex justify-content-between">
                <span className="d-inline-block mr-2">Tid</span>
              </label>

              <div className="d-flex">
                {showSignControl && (
                  <TimeFieldsSignControl setTimeFields={setTimeFields} timeFields={timeFields} signMultiplier={signMultiplier} />
                )}
                <div className="mr-2 time-picker-field">
                  <HoursPicker
                    hours={hours}
                    setHours={h => setTimeFields({ ...timeFields, time: (h * 60 + minutes) * signMultiplier })}
                    name={fieldNameFn('hours')}
                  />
                </div>

                <div className="time-picker-field">
                  <MinutesPicker
                    minutes={minutes}
                    setMinutes={m => setTimeFields({ ...timeFields, time: (hours * 60 + m) * signMultiplier })}
                    name={fieldNameFn('minutes')}
                  />
                  <input className="invisible-field" type="number" onChange={() => {}} value={hours * 60 + minutes} min={5} />
                </div>
              </div>
            </div>
            <input type="hidden" name={fieldNameFn('time')} value={(hours * 60 + minutes) * signMultiplier} min={5} />
            {timeId && <input type="hidden" name={fieldNameFn('id')} value={timeId} />}
          </div>
        </div>
      </div>

      <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />
    </fieldset>
  )
}

TimeFields.propTypes = {
  idKey: PropTypes.string.isRequired,
  timeFields: PropTypes.shape({
    time: PropTypes.number,
  }).isRequired,
  setTimeFields: PropTypes.func.isRequired,
  availableWorktypes: PropTypes.array.isRequired,
}

export default TimeFields
