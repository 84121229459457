import * as React from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import FormFields from './FormFields'
import useResourcesRecordFormController from '../../hooks/useResourcesRecordFormController'

const queryClient = buildQueryClient()

export default function ResourcesRecordsApp(props: any) {
  const { formData, setFormData } = useResourcesRecordFormController(props.record)
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <FormFields {...props} formData={formData} setFormData={setFormData} />
      </QueryClientProvider>
    </>
  )
}
